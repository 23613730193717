import React from "react";
import signatureImage from "./vishnu-signature.png";

const Signature = () => (
  <div style={{ textAlign: "left" }}>
    <img src={signatureImage} alt="Vishnu's signature" width="110px" height="120px"/>
  </div>
);

export default Signature;
