import React, { PropsWithChildren } from 'react';

/**
 * It is tricky to use Bootstrap to style tables written in markdown, and so we
 * need to keep this in HTML.
 */
const ImprovementsTable: React.FC = () => {
    return (
        <div className="table-responsive">
            <table className="table w-auto">
                <thead className="">
                    <tr>
                        <th>File size</th>
                        <th>Before</th>
                        <th>After</th>
                        <th className="text-end">Improvement</th>
                    </tr>
                </thead>
                <tbody className="table-group-divider">
                    <tr>
                        <td>2.6 MB</td>
                        <td>5.3 s</td>
                        <td>4.1 s</td>
                        <ImprovementTd>23.42%</ImprovementTd>
                    </tr>
                    <tr>
                        <td>2.7 MB</td>
                        <td>4.3 s</td>
                        <td>5.6 s</td>
                        <ImprovementTd>28.88%</ImprovementTd>
                    </tr>
                    <tr>
                        <td>3.3 MB</td>
                        <td>8.1 s</td>
                        <td>8.1 s</td>
                        <ImprovementTd>0.46%</ImprovementTd>
                    </tr>
                    <tr>
                        <td>23 MB</td>
                        <td>23 s</td>
                        <td>21 s</td>
                        <ImprovementTd>8.60%</ImprovementTd>
                    </tr>
                    <tr>
                        <td>141 MB</td>
                        <td>119 s</td>
                        <td>82 s</td>
                        <ImprovementTd>31.20%</ImprovementTd>
                    </tr>
                    <tr>
                        <td>354 MB</td>
                        <td>306 s</td>
                        <td>217 s</td>
                        <ImprovementTd>29.01%</ImprovementTd>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

const ImprovementTd: React.FC<PropsWithChildren> = ({ children }) => {
    return <td className="text-success text-end">{children}</td>;
};

export default ImprovementsTable;
