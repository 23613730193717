import Toggle from "components/Toggle";
import { Link } from "gatsby";
import ArrowRight from "images/homepage/ArrowRight";
import React, { ReactNode, useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import styled from "styled-components";
import { BillingPlans, IPlan, fetchPlans } from "utils/plan-service";
import { OS, getDeviceOS } from "utils/detect-os";
import { DownloadURL } from "constants/download";

const Label = styled.div<{ active: boolean }>`
    flex: 1;
    font-weight: 700;

    & > span {
        transition: opacity 0.25s ease-out;
        opacity: ${(props) => (props.active ? "1" : "0.5")};
    }
`;

const CardWrapper = styled.div`
    background: linear-gradient(180deg, #d8d8d855 0%, #a0e2b7 100%);
    padding: 2px;
    border-radius: 16px;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    flex: 1;
`;

const Card = styled.div`
    border-radius: 16px;
    background: #fff;
    padding: 28px 50px 60px 50px;
    font-family: Gilroy;
    display: flex;
    flex-direction: column;
    flex: 1;

    @media (min-width: 768px) {
        padding: 28px;
    }

    @media (min-width: 1200px) {
        padding: 28px 20px;
    }

    @media (min-width: 1400px) {
        padding: 28px;
    }
`;

const PlanLabel = styled.div`
    color: #1d1d1f;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    opacity: 0.5;
    margin-bottom: 7px;
`;

const FreePlanLabel = styled.div`
    color: rgb(0, 179, 60);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 7px;
`;

const PlanValue = styled.div<{ showAnnual?: boolean }>`
    color: #1d1d1f;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 21px;
    height: 49px;
    overflow: hidden;

    & > div {
        transition: transform .3s ease-out 0s;
    }

    &:hover > div {
        transform: translateY(${props => props.showAnnual ? '-49px' : '0px'});
    }
`;

const PlanValueBold = styled(PlanValue)`
    font-weight: 700;
`;

const Popular = styled(PlanLabel)`
    color: #ffcd3f;
    font-weight: 700;
    opacity: 1;
    margin-top: -28px;
    background: linear-gradient(180deg, #20d861 0%, #18ab4c 100%);
    padding: 14px 0;
    text-align: center;
    width: 100px;
    color: #fff;
    border-radius: 0 0 12px 12px;
`;

const PlanValueMinor = styled.span`
    color: #1d1d1f;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 21px;
`;

const PlanPeriod = styled.span`
    color: #272522;
    font-family: "Nunito";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

const PlanDescription = styled.p`
    color: rgb(120, 120, 120);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    flex: 1;
    margin-top: 0;
    margin-bottom: 42px;
`;

const PlanCTA = styled.button`
    font-family: "Nunito";
    font-weight: 600;
    border: none;
    color: #fff;
    border-radius: 100px;
    background: #434343;
    padding: 10px 26px;
    width: 160px;
    display: inline-flex;
    justify-content: space-between;
    transition:
        width 0.1s linear,
        background-color 0.1s linear;
    text-decoration: none;
    max-width: 172px;

    &:focus {
        outline: 4px solid rgba(0, 0, 0, 0.2);
    }

    &:hover {
        width: 100%;
        background-color: #1db954;
    }
`;

const PricingCarouselItem = styled(Carousel.Item)`
    transition: transform 0.2s ease;
`;

const FreeTrialSection = styled.button`
    margin-bottom: 72px;
    background-color: #00b33c;
    padding: 16px 26px;
    width: 240px;
    display: inline-flex;
    justify-content: space-between;
    font-family: "Nunito";
    font-weight: 600;
    border: none;
    color: #fff;
    border-radius: 100px;
    &:hover {
        transition: all 0.2s ease-in-out;
        box-shadow: 0 0 4px #1db954;
    }
`;

const PricingItem = styled.div.attrs({ className: 'px-1 text-start mb-3 d-flex blog-pricing-item' })`
    display: flex;

    @media (min-width: 768px) {
        max-width:33% !important; 
    }

    @media (min-width: 1200px) {
        max-width: 20% !important;
    }
`;

const splitPrice = (price: string) => price.split(".");

const getMonthlyAmount = (annualPrice: string) => {
    const monthly = parseFloat(annualPrice) / 12;
    return monthly.toFixed(2) + "";
};

const getPlanCard = (
    plan: IPlan,
    shouldShowMonthly: boolean,
    title: string,
    description: ReactNode,
    isPopularPlan?: boolean,
) => {
    const amount = plan.price.substring(1);
    const [major, minor] = splitPrice(
        shouldShowMonthly ? amount : getMonthlyAmount(amount),
    );
    const [hoverMajor, hoverMinor] = splitPrice(amount);
    const isFreePlan = plan.id === "free";
    const os = getDeviceOS();
    return (
        <CardWrapper>
            <Card>
                {isPopularPlan ? (
                    <Popular>{title}</Popular>
                ) : isFreePlan ? (
                    <FreePlanLabel>Free</FreePlanLabel>
                ) : (
                    <PlanLabel>{title}</PlanLabel>
                )}
                <PlanValueBold className="mt-3">{plan.size} GB</PlanValueBold>
                {isFreePlan ? (
                    <PlanValue showAnnual={!shouldShowMonthly}>
                        <div>
                            {plan.price.substring(0, 1)}
                            {major}
                            <PlanPeriod>/mo</PlanPeriod>
                        </div>
                        <div>
                            {plan.price.substring(0, 1)}
                            {hoverMajor}
                            <PlanPeriod>/yr</PlanPeriod>
                        </div>
                    </PlanValue>
                ) : (
                    <PlanValue showAnnual={!shouldShowMonthly}>
                        <div>
                            {plan.price.substring(0, 1)}
                            {major}
                            {minor && minor !== "00" && <PlanValueMinor>.{minor}</PlanValueMinor>}
                            <PlanPeriod>/mo</PlanPeriod>
                        </div>
                        <div>
                            {plan.price.substring(0, 1)}
                            {hoverMajor}
                            {hoverMinor && <PlanValueMinor>.{hoverMinor}</PlanValueMinor>}
                            <PlanPeriod>/yr</PlanPeriod>
                        </div>
                    </PlanValue>
                )}
                <PlanDescription>{description}</PlanDescription>
                <Link
                    to={os === OS.IOS ? DownloadURL.appStore : "#download"}
                    tabIndex={-1}
                >
                    <PlanCTA>
                        Try it now <ArrowRight />
                    </PlanCTA>
                </Link>
            </Card>
        </CardWrapper>
    );
};

const planDescription = [
    <>Casual users with a small library of photos</>,
    <>Customers who capture photos infrequently</>,
    <>Enthusiasts who capture and share photos</>,
    <>Family members; offers a mix of storage and value</>,
    <>Professionals, heavy users, and creators</>,
];

const getPlanCards = (
    filteredPlans: IPlan[],
    shouldShowMonthly: boolean,
    carousel?: boolean,
) => {
    return filteredPlans.map((plan, index) => {
        const isPopular = index === filteredPlans.length - 3;
        const title = ["Free", "Starter", "Popular", "Pro", "Pro+"];
        return carousel ? (
            <PricingCarouselItem key={plan.id}>
                {getPlanCard(
                    plan,
                    shouldShowMonthly,
                    title[index],
                    planDescription[index],
                    isPopular,
                )}
            </PricingCarouselItem>
        ) : (
            <PricingItem
                key={plan.id}
            >
                {getPlanCard(
                    plan,
                    shouldShowMonthly,
                    title[index],
                    planDescription[index],
                    isPopular,
                )}
            </PricingItem>
        );
    });
};
export default function PricingPlans() {
    const [billingPlans, setBillingPlans] = useState({} as BillingPlans);
    const [shouldShowMonthly, setShouldShowMonthly] = useState(false);

    useEffect(() => {
        fetchPlans().then(setBillingPlans);
    }, []);

    const handleToggle = function () {
        setShouldShowMonthly(!shouldShowMonthly);
    };

    const filterPeriod = shouldShowMonthly ? "month" : "year";
    const filteredPlans =
        billingPlans.plans?.filter((plan) => plan.period === filterPeriod) ??
        [];
    if (billingPlans.freePlan) {
        filteredPlans.unshift({
            id: "free",
            size: billingPlans.freePlan!.size,
            price: `${billingPlans.plans?.[0].price.substring(0,1)}0`,
            period: "year",
        });
    }

    return (
        <>
            <div className="d-flex align-items-center">
                <Label
                    active={shouldShowMonthly}
                    className="text-sm-end text-center"
                >
                    <span>Monthly</span>
                </Label>
                <div>
                    <Toggle
                        isOn={!shouldShowMonthly}
                        offLabel=""
                        onLabel=""
                        onToggle={handleToggle}
                    />
                </div>
                <Label
                    active={!shouldShowMonthly}
                    className="text-sm-start text-center"
                >
                    <span>Annually</span>
                </Label>
            </div>
            <div className="mt-4 d-md-none text-start">
                <Carousel interval={null} className="carousel-dark">
                    {getPlanCards(filteredPlans, shouldShowMonthly, true)}
                </Carousel>
            </div>
            <div className="row mt-4 d-none d-md-flex justify-content-center">
                {getPlanCards(filteredPlans, shouldShowMonthly)}
            </div>
        </>
    );
}
