export enum OS {
    WP = "wp",
    Android = "android",
    IOS = "ios",
    Windows = "windows",
    Mac = "mac",
    Linux = "linux",
}

declare global {
    interface Window {
        opera: any;
        MSStream: any;
    }
}

/** Try to detect the user's operating system. */
export const getDeviceOS = (): OS | undefined => {
    let userAgent = "";
    if (
        typeof window !== "undefined" &&
        typeof window.navigator !== "undefined"
    ) {
        userAgent = navigator.userAgent || navigator.vendor || window.opera;
    }
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return OS.WP;
    }

    if (/android/i.test(userAgent)) {
        return OS.Android;
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/(iPad|iPhone|iPod)/g.test(userAgent) && !window.MSStream) {
        return OS.IOS;
    }

    // credit: https://github.com/MikeKovarik/platform-detect/blob/master/os.mjs
    if (userAgent.includes("Windows")) {
        return OS.Windows;
    }
    if (userAgent.includes("Macintosh")) {
        return OS.Mac;
    }
    // Linux must be last
    if (userAgent.includes("Linux")) {
        return OS.Linux;
    }

    return;
};
